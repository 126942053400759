/**
 * This file provides the "gr_mapfish3" namespace, which is the
 * application's main namespace. And it defines the application's Angular
 * module.
 */
import {decodeQueryString} from 'ngeo/utils.js';
import angular from 'angular';
import ngeoGetBrowserLanguage from './browser-language/getBrowserLanguage.js';

/**
 * @type {!angular.IModule}
 */
const gr_mapfish3Module = angular.module('gr_mapfish3', [ngeoGetBrowserLanguage.name]);

gr_mapfish3Module.config([
  '$compileProvider',
  function ($compileProvider) {
    if (!('debug' in decodeQueryString(window.location.search))) {
      // Disable the debug info
      $compileProvider.debugInfoEnabled(false);
    }
  },
]);

export default gr_mapfish3Module;
