/*
 * Common functions to create a polygon from a line.
 */
import ngeoInteractionMeasureLength from 'ngeo/interaction/MeasureLength.js';
import olStyleStyle from 'ol/style/Style.js';
import olFeature from 'ol/Feature.js';
import Point from 'ol/geom/Point.js';
import Polygon from 'ol/geom/Polygon.js';
import LineString from 'ol/geom/LineString.js';
import LinearRing from 'ol/geom/LinearRing.js';
import MultiPoint from 'ol/geom/MultiPoint.js';
import MultiLineString from 'ol/geom/MultiLineString.js';
import MultiPolygon from 'ol/geom/MultiPolygon.js';
import GeometryCollection from 'ol/geom/GeometryCollection.js';
// @ts-ignore: not supported import
import {OL3Parser} from 'jsts/io';
import 'jsts/monkey.js';

/**
 * Strongly inspired by ngeo/measure/length.js
 * Create a measure line (draw line) interaction.
 * @param {!angular.IScope} $scope Scope.
 * @param {!angular.IFilterService} $filter Angular filter.
 * @param {!angular.auto.IInjectorService} $injector Main injector.
 * @param {!angular.ICompileService} $compile Angular compile service.
 * @param {!angular.gettext.gettextCatalog} gettextCatalog Gettext catalog.
 * @return {import("ol/interaction/Draw.js").default|import("ngeo/interaction/DrawAzimut.js").default|import("ngeo/interaction/MobileDraw.js").default}
 *    The interaction
 */
export function createLinepolygonInteraction($scope, $filter, $injector, $compile, gettextCatalog) {
  const helpMsg = gettextCatalog.getString('Click to start drawing line');
  const contMsg = gettextCatalog.getString(
    'Click to continue drawing<br>' + 'Double-click or click last point to finish'
  );

  const measureLength = new ngeoInteractionMeasureLength($filter('ngeoUnitPrefix'), gettextCatalog, {
    style: new olStyleStyle(),
    startMsg: $compile(`<div translate>${helpMsg}</div>`)($scope)[0],
    continueMsg: $compile(`<div translate>${contMsg}</div>`)($scope)[0],
    precision: $injector.has('ngeoMeasurePrecision') ? $injector.get('ngeoMeasurePrecision') : undefined,
    tolerance: $injector.has('ngeoSnappingTolerance') ? $injector.get('ngeoSnappingTolerance') : undefined,
    source: $injector.has('ngeoSnappingSource') ? $injector.get('ngeoSnappingSource') : undefined,
  });
  return measureLength;
}

/**
 * Create and return a Jsts Openlayers parser.
 * @return {import("jsts/io").OL3Parser} The parser.
 */
export function createJstsOL3Parser() {
  return new OL3Parser(undefined, {
    geom: {
      Point,
      LineString,
      LinearRing,
      Polygon,
      MultiPoint,
      MultiLineString,
      MultiPolygon,
      GeometryCollection,
    },
  });
}

/**
 * Transform a line to a polygon.
 * @param {import("ol/Feature.js").default} line The base line.
 * @param {number} buffer the buffer around the line to
 *     create the polygon.
 * @param {import("jsts/io").OL3Parser} a Jsts Openlayers parser.
 */
export function lineToLinepolygon(line, buffer, jstsOL3Parser) {
  let jstsLine = jstsOL3Parser.read(line.getGeometry());
  jstsLine = jstsLine.buffer(buffer, 8, 2);
  return new olFeature({
    geometry: jstsOL3Parser.write(jstsLine),
  });
}
